import React from "react";
import { graphql } from "gatsby";
import PropTypes  from "prop-types";
import Layout from "views/Layout";
import HomePage from "../pageTemplates/HomePage";

const HomeIndexDE = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        seoData,
        header,
        imageFileName,
        topContent,
        contentData,
      },
    },
  } = data;

  const topData = {
    header,
    imageFileName,
    topContent,
  };

  return (
    <Layout
      langKey="de"
      seo={seoData}
    >
      <HomePage
        top={topData}
        sections={contentData}
      />
    </Layout>
  );
};

export default HomeIndexDE;

HomeIndexDE.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query HomeIndexDEQuery {
    markdownRemark(
      fileAbsolutePath: {regex: "/home.de.md$/" }
    ) {
      html
      frontmatter {
        seoData {
          title
          description
        }
        header
        imageFileName
        topContent
        contentData {
          anchor
          header
          body {
            image
            body {
              headline
              content
            }
          }
        }
      }
    }
  }
`;
